import Image from 'next/image';

export const RoundedIconButton = ({
  onClick,
  src,
  alt,
  className,
}: {
  onClick: any;
  src: string;
  alt: string;
  className?: string;
}) => (
  <div className={className}>
    <button
      onClick={onClick}
      className="w-[40px] h-[40px] rounded-full shadow   flex justify-center items-center bg-sys/primary/primary text-sys/primary/on-primary"
    >
      <Image src={src} width={24} height={24} alt={alt} />
    </button>
  </div>
);
